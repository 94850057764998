/** @format */

import { Dropdown } from "antd";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import "./translate.js";
const data = [
  { lang: "English", code: "GB" },
  { lang: "Chinese", code: "CN" },
];
function LangSelect(props) {
  const [langData, setData] = useState(data);
  const [sel, setSel] = useState(
    localStorage.getItem("locale") === "English" ? 0 : 1,
  );
  const [t, i18n] = useTranslation();

  const onSelectLanguage = idx => { 
    setSel(idx);
    i18n.changeLanguage(langData[idx].lang);
    localStorage.setItem("locale", langData[idx].lang);
  };
  const menu = (
    <div className='bg-white shadow px-2 py-2 '>
      {langData.map((item, idx) => (
        <div  style={{cursor:"pointer"}}
          className='flex justify-between items-center   w-full mt-2 grayButtn rounded-md p-2'
          key={idx}
          onClick={() => onSelectLanguage(idx)}>
          <span className=' font-bold'>{item.lang} </span> &nbsp;
          <ReactCountryFlag
            className='rounded-circle '
            style={{
              width: "1.5em",
              height: "1.5em",
            }}
            countryCode={item.code}
            svg
            cdnUrl='https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/'
            cdnSuffix='svg'
            title='US'
          />
        </div>
      ))}
    </div>
  );
  return (
    <Dropdown
      overlay={menu}
      placement='bottomLeft'
      className={`${props.className} `}>
      <span style={{cursor:"pointer"}} className='flex justify-center items-center '>
        <span className=' font-bold'>{langData[sel].lang} </span> &nbsp;
        <ReactCountryFlag
          className='rounded-circle '
          style={{
            width: "1.5em",
            height: "1.5em",
          }}
          countryCode={langData[sel].code}
          svg
          cdnUrl='https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/'
          cdnSuffix='svg'
          title='US'
        />
      </span>
    </Dropdown>
  );
}
export default LangSelect;
