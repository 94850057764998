/** @format */

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const MobileScreen = () => {
  const [t, i18n] = useTranslation();

  const [vid1, setvid1] = useState(true);
  const [vid2, setvid2] = useState(false);
  const [vid3, setvid3] = useState(false);
  const [vid4, setvid4] = useState(false);
  const [vid5, setvid5] = useState(false);
  return (
    <div className='container mobileScreen'>
      <div className='row'>
        <h2>{t("Bubble 4% Bot")} </h2>
      </div>
      <div className='row'>
        <div className='col-lg-6 '>
          {vid1 ? (
            <video
              className='w-100  mt-3 z-2 '
              autoPlay
              muted
              loop
              playsInline
              title='future trading'>
              <source src='https://i.imgur.com/RC9SFdW.mp4' type='video/mp4' />
            </video>
          ) : null}
          {vid2 ? (
            <video
              className='w-100  mt-3 z-2'
              autoPlay
              muted
              loop
              playsInline
              title='BNB Miner'>
              <source src='https://i.imgur.com/imjKQIN.mp4' type='video/mp4' />
            </video>
          ) : null}
          {vid3 ? (
            <video
              className='w-100  mt-3 z-2'
              title='Staking'
              autoPlay
              muted
              loop
              playsInline>
              <source src='https://i.imgur.com/zrzeb3g.mp4' type='video/mp4' />
            </video>
          ) : null}
          {vid4 ? (
            <video
              className='w-100  mt-3 z-2'
              title='spot trading'
              autoPlay
              muted
              loop
              playsInline>
              <source src='https://i.imgur.com/T92afP0.mp4' type='video/mp4' />
            </video>
          ) : null}
          {vid5 ? (
            <video
              className='w-100  mt-3 z-2'
              title='hold to earn'
              autoPlay
              muted
              loop
              playsInline>
              <source src='https://i.imgur.com/3hCrChJ.mp4' type='video/mp4' />
            </video>
          ) : null}
        </div>
        <div className='col-sm-6'>
          <button
            className='nft_box '
            onClick={() => {
              setvid1(false);
              setvid2(false);
              setvid3(false);
              setvid4(true);
              setvid5(false);
            }}>
            <p className='mt-2 p2'>
              <strong>{t("Spot Trading with Stop Loss")}</strong>
            </p>
          </button>

          {/*  */}
          <button
            className='nft_box '
            onClick={() => {
              setvid1(true);
              setvid2(false);
              setvid3(false);
              setvid4(false);
              setvid5(false);
            }}>
            <p className='mt-2 p2'>
              <strong>{t("Futures Trading")}</strong>
            </p>
          </button>
          {/*  */}
          <button
            className='nft_box  '
            onClick={() => {
              setvid1(false);
              setvid2(false);
              setvid3(true);
              setvid4(false);
              setvid5(false);
            }}>
            <p className='mt-1 p2'>
              <strong>{t("1st Multi-Token TG Staking")}</strong>
            </p>
          </button>

          <button
            className='nft_box  '
            onClick={() => {
              setvid1(false);
              setvid2(true);
              setvid3(false);
              setvid4(false);
              setvid5(false);
            }}>
            <p className='mt-2 p2'>
              <strong>{t("BNB Miner Pool")}</strong>
            </p>
          </button>

          {/*  */}
          <button
            className='nft_box  '
            onClick={() => {
              setvid1(false);
              setvid2(false);
              setvid3(false);
              setvid4(false);
              setvid5(true);
            }}>
            <p className='mt-2 p2'>
              <strong>{t("Hold to earn")}</strong>
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileScreen;
