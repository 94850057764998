/** @format */

import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BsFillCheckCircleFill } from "react-icons/bs";

function BotFeatures() {
  return (
    <div className='container-fluid mt-5 pt-5 botFeatures' id='feature'>
      <div className='container'>
        <div className='row text-center'>
          <div className='col-md-12 '>
            <h5>FEATURES</h5>
            <h2>Bubble Bot Exclusive Features</h2>
            <p className=' mt-2 secp1 text-center  m-auto'>
              BubbleBot provides an extensive set of features crafted to enhance
              your chances of success.
            </p>
          </div>
        </div>

        <div className='row mt-5 '>
          <div className='col-md-12'>
            <Tabs
              defaultActiveKey='Spot'
              id='uncontrolled-tab-example'
              className='mb-3'>
              <Tab
                eventKey='Spot'
                title={
                  <span>
                    <BsFillCheckCircleFill /> &nbsp; Spot Trading with Stop Loss
                  </span>
                }>
                <div className='row section3  sec3-br'>
                  <div className='col-lg-6  m-auto'>
                    <div className='rig m-auto'>
                      <h2>Spot Trading with Stop Loss</h2>
                      <p>
                        Seamless trading experience for day traders with 'Spot
                        Trading.' Now, you can trade any token with just a few
                        clicks using <strong>@Bubblebotfin</strong> Telegram
                        bot, just like having a regular chat. It's faster, more
                        efficient, and offers a fantastic user experience. To
                        activate Limit Orders, just switch the On/Off button,
                        and you'll see options to configure Stop Loss and Take
                        Profit.
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6 pt-5 pb-5 m-auto'>
                    <div className='grBox'>
                      <iframe
                        width='100%'
                        height={300}
                        src=''
                        title='YouTube video player'
                        frameBorder={0}
                        className='iframes w-100 mt-3'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowFullScreen=''
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey='Future Trading'
                title={
                  <span>
                    <BsFillCheckCircleFill />
                    &nbsp; Future Trading
                  </span>
                }>
                <div className='row section3  sec3-br'>
                  <div className='col-lg-6  m-auto'>
                    <div className='rig m-auto'>
                      <h2>Future Trading</h2>
                      <p>
                        First Futures Trading Bot, turning your Telegram chat
                        into a CHEX (Chat + Exchange) in less than a minute.
                        Bubble holders can now open long or short positions,
                        including top-tier assets like <strong>$ETH</strong> and{" "}
                        <strong>UniBot</strong>, as well as the latest trending
                        memecoins! Our Futures smart contracts feature a 36-hour
                        closure cycle.
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6 pt-5 pb-5 m-auto'>
                    <div className='grBox'>
                      <iframe
                        width='100%'
                        height={300}
                        src=''
                        title='YouTube video player'
                        frameBorder={0}
                        className='iframes w-100 mt-3'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowFullScreen=''
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey='Multi-Token'
                title={
                  <span>
                    <BsFillCheckCircleFill />
                    &nbsp; 1st Multi-Token TG Staking
                  </span>
                }>
                <div className='row section3  sec3-br'>
                  <div className='col-lg-6  m-auto'>
                    <div className='rig m-auto'>
                      <h2>1st Multi-Token TG Staking</h2>
                      <p>
                        Bubblebot is harnessing the capabilities of multi-token
                        technology on the Ethereum network, our community
                        members have the opportunity to earn rewards in a
                        variety of bot token currencies, including{" "}
                        <strong>UniBot</strong>, <strong>OCD</strong>,
                        <strong> AIM Bot</strong>, and <strong>ETH</strong>.
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6 pt-5 pb-5 m-auto'>
                    <div className='grBox'>
                      <iframe
                        width='100%'
                        height={300}
                        src=''
                        title='YouTube video player'
                        frameBorder={0}
                        className='iframes w-100 mt-3'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowFullScreen=''
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey='Hedge/Risk'
                title={
                  <span>
                    <BsFillCheckCircleFill />
                    &nbsp; Hedge/Risk Protocol
                  </span>
                }>
                <div className='row section3  sec3-br'>
                  <div className='col-lg-6  m-auto'>
                    <div className='rig m-auto'>
                      <h2>Hedge/Risk Protocol</h2>
                      <p>
                        Bubble Hedge-Risk is a DeFi insurance protocol that
                        allows third-party participants to speculate on the
                        performance of underlying pegged assets depending on
                        their performance histories, including{" "}
                        <strong>USDC</strong>, <strong>DAI</strong>,{" "}
                        <strong>USDT</strong>
                        and many other stable tokens.
                      </p>
                      <ul>
                        <li>
                          <strong>Hedge vaults</strong> are based on the hedging
                          strategy. Users can deposit $Bubble tokens to utilize
                          this vault to hedge against the assets that are prone
                          to risk. Once a user takes a position in Hege vault;
                          an ERC 1155 token is minted as a representation of the
                          user's stake in the vault.
                        </li>
                        <li className='mt-4'>
                          In the <strong>RISK vault</strong> users can sell
                          their insurance by depositing $BUBBLE in the RISK
                          vault. The BUBBLE deposited are converted into a
                          semi-fungible ERC 1155 token that is tradable in the
                          vault.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-6 pt-5 pb-5 m-auto'>
                    <div className='grBox'>
                      <iframe
                        width='100%'
                        height={300}
                        src=''
                        title='YouTube video player'
                        frameBorder={0}
                        className='iframes w-100 mt-3'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowFullScreen=''
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey='Lender/Borrower'
                title={
                  <span>
                    <BsFillCheckCircleFill /> &nbsp; Lender/Borrower
                  </span>
                }>
                <div className='row section3  sec3-br'>
                  <div className='col-lg-6  m-auto'>
                    <div className='rig m-auto'>
                      <h2>Lender/Borrower</h2>
                      <p>
                        For short positions, there is a corresponding lending
                        pool for each token listed on <strong>Bubblebot</strong>. In this
                        scenario, liquidity providers lend tokens to facilitate
                        shorting. Anyone can become a liquidity provider or
                        Lender by contributing liquidity to the given pools.
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6 pt-5 pb-5 m-auto'>
                    <div className='grBox'>
                      <iframe
                        width='100%'
                        height={300}
                        src=''
                        title='YouTube video player'
                        frameBorder={0}
                        className='iframes w-100 mt-3'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowFullScreen=''
                      />
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BotFeatures;
