/** @format */
import Particles from "react-tsparticles";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadSlim } from "tsparticles-slim";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTelegram, FaMediumM, FaDiscord } from "react-icons/fa";
import { SiCoinmarketcap } from "react-icons/si";

const Section1 = props => {
  const [t, i18n] = useTranslation();
  const particlesInit = useCallback(async engine => {
    // console.log(engine);

    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    // await console.log(container);
  }, []);

  const [vid1, setvid1] = useState(true);
  const [vid2, setvid2] = useState(false);
  const [vid3, setvid3] = useState(false);
  const [vid4, setvid4] = useState(false);
  const [vid5, setvid5] = useState(false);
  return (
    <>
      <Particles
        id='tsparticles'
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          autoPlay: true,
          background: {
            color: {
              value: "transparent",
              opacity: 0.5,
            },
            image: "",
            position: "50% 50%",
            repeat: "no-repeat",
            size: "cover",
            opacity: 0.5,
          },
          backgroundMask: {
            composite: "destination-out",
            cover: {
              color: {
                value: "#fff",
              },
              opacity: 0.5,
            },
            enable: false,
          },
          defaultThemes: {},
          delay: 0,
          fullScreen: {
            enable: true,
            zIndex: 1,
          },
          detectRetina: true,
          duration: 0,
          fpsLimit: 120,
          interactivity: {
            detectsOn: "window",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onDiv: {
                selectors: "#repulse-div",
                enable: false,
                mode: "repulse",
                type: "circle",
              },
              onHover: {
                enable: true,
                mode: "bubble",
                parallax: {
                  enable: false,
                  force: 2,
                  smooth: 10,
                },
              },
              resize: {
                delay: 0.5,
                enable: true,
              },
            },
            modes: {
              trail: {
                delay: 1,
                pauseOnStop: false,
                quantity: 1,
              },
              attract: {
                distance: 200,
                duration: 0.4,
                easing: "ease-out-quad",
                factor: 1,
                maxSpeed: 50,
                speed: 1,
              },
              bounce: {
                distance: 200,
              },
              bubble: {
                distance: 400,
                duration: 2,
                mix: false,
                opacity: 0.5,
                size: 30,
                divs: {
                  distance: 200,
                  duration: 0.4,
                  mix: false,
                  selectors: [],
                },
              },
              connect: {
                distance: 80,
                links: {
                  opacity: 0.5,
                },
                radius: 60,
              },
              grab: {
                distance: 400,
                links: {
                  blink: false,
                  consent: false,
                  opacity: 1,
                },
              },
              push: {
                default: true,
                groups: [],
                quantity: 4,
              },
              remove: {
                quantity: 2,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
                factor: 100,
                speed: 1,
                maxSpeed: 50,
                easing: "ease-out-quad",
                divs: {
                  distance: 200,
                  duration: 0.4,
                  factor: 100,
                  speed: 1,
                  maxSpeed: 50,
                  easing: "ease-out-quad",
                  selectors: [],
                },
              },
              slow: {
                factor: 3,
                radius: 200,
              },
              light: {
                area: {
                  gradient: {
                    start: {
                      value: "#ffffff",
                    },
                    stop: {
                      value: "#000000",
                    },
                  },
                  radius: 1000,
                },
                shadow: {
                  color: {
                    value: "#000000",
                  },
                  length: 2000,
                },
              },
            },
          },
          manualParticles: [],
          particles: {
            bounce: {
              horizontal: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
              vertical: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
            },
            collisions: {
              absorb: {
                speed: 2,
              },
              bounce: {
                horizontal: {
                  random: {
                    enable: false,
                    minimumValue: 0.1,
                  },
                  value: 1,
                },
                vertical: {
                  random: {
                    enable: false,
                    minimumValue: 0.1,
                  },
                  value: 1,
                },
              },
              enable: false,
              maxSpeed: 50,
              mode: "bounce",
              overlap: {
                enable: true,
                retries: 0,
              },
            },
            color: {
              value: "#ffffff",
              animation: {
                h: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  delay: 0,
                  decay: 0,
                  sync: true,
                },
                s: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  delay: 0,
                  decay: 0,
                  sync: true,
                },
                l: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  delay: 0,
                  decay: 0,
                  sync: true,
                },
              },
            },
            groups: {},
            move: {
              angle: {
                offset: 0,
                value: 90,
              },
              attract: {
                distance: 200,
                enable: false,
                rotate: {
                  x: 600,
                  y: 1200,
                },
              },
              center: {
                x: 50,
                y: 50,
                mode: "percent",
                radius: 0,
              },
              decay: 0,
              distance: {},
              direction: "none",
              drift: 0,
              enable: true,
              gravity: {
                acceleration: 9.81,
                enable: false,
                inverse: false,
                maxSpeed: 50,
              },
              path: {
                clamp: true,
                delay: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: 0,
                },
                enable: false,
                options: {},
              },
              outModes: {
                default: "out",
                bottom: "out",
                left: "out",
                right: "out",
                top: "out",
              },
              random: false,
              size: false,
              speed: 2,
              spin: {
                acceleration: 0,
                enable: false,
              },
              straight: false,
              trail: {
                enable: false,
                length: 10,
                fill: {},
              },
              vibrate: false,
              warp: false,
            },
            number: {
              density: {
                enable: true,
                width: 1920,
                height: 1080,
              },
              limit: 0,
              value: 10,
            },
            opacity: {
              random: {
                enable: true,
                minimumValue: 0.1,
              },
              value: {
                min: 0.1,
                max: 1,
              },
              animation: {
                count: 0,
                enable: true,
                speed: 1,
                decay: 0,
                delay: 0,
                sync: false,
                mode: "auto",
                startValue: "random",
                destroy: "none",
                minimumValue: 0.2,
              },
            },
            reduceDuplicates: false,
            shadow: {
              blur: 0,
              color: {
                value: "#000",
              },
              enable: false,
              offset: {
                x: 0,
                y: 0,
              },
            },
            shape: {
              close: true,
              fill: true,
              options: {
                character: {
                  fill: false,
                  font: "Verdana",
                  style: "",
                  value: "*",
                  weight: "400",
                },
                char: {
                  fill: false,
                  font: "Verdana",
                  style: "",
                  value: "*",
                  weight: "400",
                },
                polygon: {
                  sides: 5,
                },
                star: {
                  sides: 5,
                },
                image: [
                  {
                    src: "assets/icon.png",
                    width: 50,
                    height: 50,
                  },
                  {
                    src: "assets/icon.png",
                    width: 50,
                    height: 50,
                  },
                ],
                images: [
                  {
                    src: "assets/icon.png",
                    width: 50,
                    height: 50,
                  },
                  {
                    src: "assets/icon.png",
                    width: 50,
                    height: 50,
                  },
                ],
              },
              type: "image",
            },
            size: {
              random: {
                enable: false,
                minimumValue: 1,
              },
              value: 16,
              animation: {
                count: 0,
                enable: false,
                speed: 80,
                decay: 0,
                delay: 0,
                sync: false,
                mode: "auto",
                startValue: "random",
                destroy: "none",
                minimumValue: 0.1,
              },
            },
            stroke: {
              width: 0,
              color: {
                value: "#000000",
                animation: {
                  h: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    delay: 0,
                    decay: 0,
                    sync: true,
                  },
                  s: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    delay: 0,
                    decay: 0,
                    sync: true,
                  },
                  l: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    delay: 0,
                    decay: 0,
                    sync: true,
                  },
                },
              },
            },
            zIndex: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              opacityRate: 1,
              sizeRate: 1,
              velocityRate: 1,
            },
            destroy: {
              bounds: {},
              mode: "none",
              split: {
                count: 1,
                factor: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: 3,
                },
                rate: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: {
                    min: 4,
                    max: 9,
                  },
                },
                sizeOffset: true,
                particles: {},
              },
            },
            roll: {
              darken: {
                enable: false,
                value: 0,
              },
              enable: false,
              enlighten: {
                enable: false,
                value: 0,
              },
              mode: "vertical",
              speed: 25,
            },
            tilt: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              animation: {
                enable: false,
                speed: 0,
                decay: 0,
                sync: false,
              },
              direction: "clockwise",
              enable: false,
            },
            twinkle: {
              lines: {
                enable: false,
                frequency: 0.05,
                opacity: 1,
              },
              particles: {
                enable: false,
                frequency: 0.05,
                opacity: 1,
              },
            },
            wobble: {
              distance: 5,
              enable: false,
              speed: {
                angle: 50,
                move: 10,
              },
            },
            life: {
              count: 0,
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
                sync: false,
              },
              duration: {
                random: {
                  enable: false,
                  minimumValue: 0.0001,
                },
                value: 0,
                sync: false,
              },
            },
            rotate: {
              random: {
                enable: true,
                minimumValue: 0,
              },
              value: 0,
              animation: {
                enable: true,
                speed: 5,
                decay: 0,
                sync: false,
              },
              direction: "random",
              path: false,
            },
            orbit: {
              animation: {
                count: 0,
                enable: false,
                speed: 1,
                decay: 0,
                delay: 0,
                sync: false,
              },
              enable: false,
              opacity: 1,
              rotation: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 45,
              },
              width: 1,
            },
            links: {
              blink: false,
              color: {
                value: "#000",
              },
              consent: false,
              distance: 150,
              enable: false,
              frequency: 1,
              opacity: 0.4,
              shadow: {
                blur: 5,
                color: {
                  value: "#000",
                },
                enable: false,
              },
              triangles: {
                enable: false,
                frequency: 1,
              },
              width: 1,
              warp: false,
            },
            repulse: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              enabled: false,
              distance: 1,
              duration: 1,
              factor: 1,
              speed: 1,
            },
          },
          pauseOnBlur: true,
          pauseOnOutsideViewport: true,
          responsive: [],
          smooth: false,
          style: {},
          themes: [],
          zLayers: 100,
          motion: {
            disable: false,
            reduce: {
              factor: 4,
              value: true,
            },
          },
        }}
      />
      <div className='container-fluid   section1 '>
        {/* <div className='display-5 '></div> */}
        {/* <div className='display-6 '></div> */}
        <div className='sideText'>减少努力并增加回报</div>
        <div className='sideText1'>Reduced effort and increased reward</div>

        <div className='row ' style={{ height: "70vh" }}>
          <div
            className='col-lg-4 text-end col-start'
            style={{ position: "relative", top: "23vh" }}>
            <button
              className='nft_box boxrm0'
              onClick={() => {
                setvid1(false);
                setvid2(false);
                setvid3(false);
                setvid4(true);
                setvid5(false);
              }}>
              <p className='mt-2 p2'>
                <strong>{t("Spot Trading with Stop Loss")}</strong>
              </p>
            </button>

            {/*  */}
            <button
              className='nft_box boxrm'
              onClick={() => {
                setvid1(true);
                setvid2(false);
                setvid3(false);
                setvid4(false);
                setvid5(false);
              }}>
              <p className='mt-2 p2'>
                <strong>{t("Futures Trading")}</strong>
              </p>
            </button>
            {/*  */}
            <button
              className='nft_box  boxrm1'
              onClick={() => {
                setvid1(false);
                setvid2(false);
                setvid3(true);
                setvid4(false);
                setvid5(false);
              }}>
              <p className='mt-1 p2'>
                <strong>{t("1st Multi-Token TG Staking")}</strong>
              </p>
            </button>

            <button
              className='nft_box  boxlm2'
              onClick={() => {
                setvid1(false);
                setvid2(true);
                setvid3(false);
                setvid4(false);
                setvid5(false);
              }}>
              <p className='mt-2 p2'>
                <strong>{t("BNB Miner Pool")}</strong>
              </p>
            </button>

            {/*  */}
            <button
              className='nft_box  boxlm3'
              onClick={() => {
                setvid1(false);
                setvid2(false);
                setvid3(false);
                setvid4(false);
                setvid5(true);
              }}>
              <p className='mt-2 p2'>
                <strong>{t("Hold to earn")}</strong>
              </p>
            </button>
          </div>
          <div className='col-lg-2  m-auto'></div>
          <div className='col-lg-4 '>
            <div
              className=' d-flex align-content-center gap-3 position-absolute flex-wrap'
              style={{ top: "40%", right: "10%" }}>
              <a
                href='https://pancakeswap.finance/swap?outputCurrency=0xE04Dd89D48119f845fDdC8d90bA11667AA87D809'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src='assets/cake.svg'
                  className=' img-fluid'
                  width={80}
                  alt=''
                />
              </a>
              <a
                href='https://www.dexview.com/bsc/0xE04Dd89D48119f845fDdC8d90bA11667AA87D809'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src='assets/Dexview.png'
                  className='img-fluid '
                  width={80}
                  alt=''
                />
              </a>
            </div>
            {vid1 ? (
              <video
                className='  mt-3 '
                height={250}
                style={{ position: "relative", top: "45vh", left: 25 }}
                autoPlay
                muted
                loop
                title='future trading'
                playsInline>
                <source
                  src='https://i.imgur.com/RC9SFdW.mp4'
                  type='video/mp4'
                />
              </video>
            ) : null}
            {vid2 ? (
              <video
                className='  mt-3 '
                height={250}
                style={{ position: "relative", top: "45vh", left: 25 }}
                autoPlay
                muted
                title='BNB Miner'
                loop
                playsInline>
                <source
                  src='https://i.imgur.com/imjKQIN.mp4'
                  type='video/mp4'
                />
              </video>
            ) : null}
            {vid3 ? (
              <video
                className='  mt-3 '
                height={250}
                style={{ position: "relative", top: "45vh", left: 25 }}
                autoPlay
                muted
                title='Staking'
                loop
                playsInline>
                <source
                  src='https://i.imgur.com/zrzeb3g.mp4'
                  type='video/mp4'
                />
              </video>
            ) : null}
            {vid4 ? (
              <video
                className='  mt-3 '
                height={250}
                style={{ position: "relative", top: "45vh", left: 25 }}
                autoPlay
                muted
                loop
                title='spot trading'
                playsInline>
                <source
                  src='https://i.imgur.com/T92afP0.mp4'
                  type='video/mp4'
                />
              </video>
            ) : null}
            {vid5 ? (
              <video
                className='  mt-3 '
                height={250}
                style={{ position: "relative", top: "45vh", left: 25 }}
                autoPlay
                muted
                loop
                title='hold to earn'
                playsInline>
                <source
                  src='https://i.imgur.com/3hCrChJ.mp4'
                  type='video/mp4'
                />
              </video>
            ) : null}
          </div>
        </div>
      </div>
      <div className='container pt-5 mt-3  pb-5'>
        <div>
          <div className='row'>
            <div className='col-md-4 pt-3'>
              <div className='text-light '>© Bubble Bot All Right Reserved</div>
            </div>
            <div className='col-md-6  '>
              <div className='footer_icon  text-start'>
                <a
                  href='https://twitter.com/teamBubblebot'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Twitter'>
                  <strong className='m-3'>
                    <RiTwitterXFill />
                  </strong>
                </a>
                <a
                  href='https://t.me/teamBubblebot'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Telegram'>
                  <strong className='m-3'>
                    <FaTelegram />
                  </strong>
                </a>
                <a
                  href='https://discord.gg/xDnd8CzP'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Discord'>
                  <strong className='m-3'>
                    <FaDiscord />
                  </strong>
                </a>
                {/* <a
                  href='##!'
                  // target='_blank'
                  rel='noopener noreferrer'
                  title='CoinGecko'>
                  <img
                    src='assets/cg.svg'
                    width={50}
                    className='d-block'
                    alt=''
                  />
                </a> */}
                <a
                  href='https://coinmarketcap.com/community/profile/Teambubblebot/'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='CoinMarketCap'>
                  <strong className='m-3'>
                    <SiCoinmarketcap />
                  </strong>
                </a>
                {/* <a
                  href='https://www.dextools.io/app/en/ether/pair-explorer/'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Dextools'>
                  <img
                    src='assets/dex.svg'
                    width={20}
                    className='d-block mx-3'
                    alt=''
                  />
                </a> */}
                <a
                  href='https://bubblebot.medium.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Medium'>
                  <strong className='m-3'>
                    <FaMediumM />
                  </strong>
                </a>
                <a
                  href='https://blocksafu.com/audit/0xE04Dd89D48119f845fDdC8d90bA11667AA87D809'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='BlockSafu'>
                  <img
                    src='assets/audit.png'
                    width={30}
                    className='d-block mx-3'
                    alt=''
                  />
                </a>
                <a
                  href='https://dune.com/bubblebot'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='DUNE'>
                  <img
                    src='assets/DuneLogoCircle.svg'
                    width={20}
                    className='d-block mx-3'
                    alt=''
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
