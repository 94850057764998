/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Base from "./components/layout/Base";
import PageNotFound from "./components/elements/PageNotFound";
import Token from "./pages/Home/Token";
import { ToastContainer } from "react-toastify";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon, goerli, bsc } from "wagmi/chains";
import ReactGA from "react-ga";
import PrivateSale from "./components/elements/PrivateSale";

function App() {
  const chains = [bsc];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <WagmiConfig config={wagmiConfig}>
      <Router>
        <Base>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            {/* <Route exact path='/airdrop'>
              <Token />
            </Route> */}
            {/* <Route exact path='/presale'>
              <PrivateSale />
            </Route> */}
            <Route component={PageNotFound} />
          </Switch>
        </Base>
      </Router>
      <ToastContainer
        position='top-center'
        toastClassName='fex-toast'
        className='fex-toast-container'
        theme='light'
      />
      <div className='z-[9999] z-2  postion-relative'>
        <Web3Modal
          projectId={projectId}
          ethereumClient={ethereumClient}
          defaultChain={1}
        />
      </div>{" "}
    </WagmiConfig>
  );
}

export default App;
