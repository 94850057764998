/** @format */

import React from "react";
import { MobileScreen, Section1 } from "../../components/elements";

const Home = () => {
  return (
    <>
      <MobileScreen />
      <Section1 />
    </>
  );
};

export default Home;
