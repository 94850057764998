/** @format */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  English: {
    translation: {
      "Home": "Home",
      "Feature": "Feature",
      "Tokenomics": "Tokenomics",
      "FAQ": "FAQ",
      "Stay up to date": "Stay up to date",
      "Don't miss the latest Bubble bot news, guides, and insights": "Don't miss the latest Bubble bot news, guides, and insights",
      "©2023 Bubble bot Copyright All Right Reserved": "©2023 Bubble bot Copyright All Right Reserved",
      "Bubble Bot": "Bubble Bot", 
      "Launch Bot": "Launch Bot",
      "Whitepaper": "Whitepaper",
      "Staking": "Staking",
      "Join the $bubble token airdrop": "Join the $bubble token airdrop",
      "Scan With QR Code": "Scan With QR Code",
      "Bubble Futures Trading Bot available on": "Bubble Futures Trading Bot available on",
      "Discord and Telegram": "Discord and Telegram",
      "BFB": "BFB",
      "Bubble Futures Trading Bot": "Bubble Futures Trading Bot",
      "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Bubble Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 24 hours after their initial launch.": "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Bubble Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 24 hours after their initial launch.",
      "FEATURES": "FEATURES",
      "First Futures Trading Bot, transforming Discord server and Telegram into a CHEX in under a minute. derivatives contracts featuring a 36-hour expiration cycle": "First Futures Trading Bot, transforming Discord server and Telegram into a CHEX in under a minute. derivatives contracts featuring a 36-hour expiration cycle",
      "Contemporary Challenges": "Contemporary Challenges",
      "The challenge associated with on-chain trading primarily stems from low liquidity levels": "The challenge associated with on-chain trading primarily stems from low liquidity levels",
      "BFB Core Features": "BFB Core Features",
      "Straightforward Structure for Margin-Based Short-Term Speculation": "Straightforward Structure for Margin-Based Short-Term Speculation",
      "BFB comprises futures": "BFB comprises futures",
      "Much like traditional futures or perpetual contracts, these contracts can be": "Much like traditional futures or perpetual contracts, these contracts can be",
      "What Exactly (BFB)": "What Exactly (BFB)",
      "Meet Bubble-Futures-Bot (BFB), an innovative derivatives contract designed to": "Meet Bubble-Futures-Bot (BFB), an innovative derivatives contract designed to",
      "As a trader:": "As a trader:",
      "You’ll borrow funds from the lending pool at an interest rate of 3.6% for a": "You’ll borrow funds from the lending pool at an interest rate of 3.6% for a",
      "As a lender:": "As a lender:",
      "You’ll provide liquidity to the lending pool earning you 3.6% interest": "You’ll provide liquidity to the lending pool earning you 3.6% interest",
      "Price Oracles:": "Price Oracles:",
      "Futures trading is all about betting against the pricing, this is why Bubble Futures": "Futures trading is all about betting against the pricing, this is why Bubble Futures",
      "BFB is Deflationary": "BFB is Deflationary",
      "While many other meme projects operate on an inflationary model, Bubble Futures Bot stands": "While many other meme projects operate on an inflationary model, Bubble Futures Bot stands",
      "Contemporary Challenges in On-Chain Trading": "Contemporary Challenges in On-Chain Trading",
      "The challenge associated with on-chain trading primarily stems from low liquidity levels. Low liquidity can pave the way for price and funding manipulations, potentially causing forced liquidations. Consequently, market makers (MMs) often lack incentives to offer liquidity on-chain. Addressing this issue, we introduce the Bubble Futures Trading Bot as a viable solution.": "The challenge associated with on-chain trading primarily stems from low liquidity levels. Low liquidity can pave the way for price and funding manipulations, potentially causing forced liquidations. Consequently, market makers (MMs) often lack incentives to offer liquidity on-chain. Addressing this issue, we introduce the Bubble Futures Trading Bot as a viable solution",
      "- Straightforward Structure for Margin-Based Short-Term Speculation.": "- Straightforward Structure for Margin-Based Short-Term Speculation.",
      "- Daily Closure Ensured with Guaranteed $USDC Settlement": "- Daily Closure Ensured with Guaranteed $USDC Settlement",
      "- Final Settlement Aligned with the Underlying Asset Price Oracle atExpiration.": "- Final Settlement Aligned with the Underlying Asset Price Oracle atExpiration.",
      "- Absence of day trading funding payments.": "- Absence of day trading funding payments.",
      "- Open for Creation by Any User, for Any Asset, Assuming Availability of an On-Chain Oracle for Underlying Asset Price Reference": "- Open for Creation by Any User, for Any Asset, Assuming Availability of an On-Chain Oracle for Underlying Asset Price Reference",
      "- No Borrowing Required for Shorting the Underlying Asset": "- No Borrowing Required for Shorting the Underlying Asset",
      "BFB comprises futures smart contracts characterized by a 36-hour closure cycle": "BFB comprises futures smart contracts characterized by a 36-hour closure cycle",
      "Much like traditional futures or perpetual contracts, these contracts can be traded in both long and short positions throughout their duration. Any positions that remain open at expiration, without being closed or rolled over, will be settled directly back into the trader's account as $USDC. This straightforward structure empowers traders to readily engage in speculative activities—whether it's going long or short—on their preferred crypto assets, including BFB on top-tier assets such as $BTC and $ETH, as well as the latest trending memecoins!": "Much like traditional futures or perpetual contracts, these contracts can be traded in both long and short positions throughout their duration. Any positions that remain open at expiration, without being closed or rolled over, will be settled directly back into the trader's account as $USDC. This straightforward structure empowers traders to readily engage in speculative activities—whether it's going long or short—on their preferred crypto assets, including BFB on top-tier assets such as $BTC and $ETH, as well as the latest trending memecoins!",
      "While perpetual futures have gained popularity as a means of crypto speculation, their complexity has often hindered retail participation. Bubble dingdang.io Futures Bot offers a more streamlined trading solution, making it accessible to a wider audience.": "While perpetual futures have gained popularity as a means of crypto speculation, their complexity has often hindered retail participation. Bubble dingdang.io Futures Bot offers a more streamlined trading solution, making it accessible to a wider audience.",
      "Using Bubble Futures Bot as the first financial product offering (FPO) we encourage the community to provide liquidity to the lending pool just like users hold money in their banks ( while banks screw them up by making a lot of money on their money and rewarding them with almost nothing).": "Using Bubble Futures Bot as the first financial product offering (FPO) we encourage the community to provide liquidity to the lending pool just like users hold money in their banks ( while banks screw them up by making a lot of money on their money and rewarding them with almost nothing).",
      "What Exactly Is the Bubble Futures Bot": "What Exactly Is the Bubble Futures Bot",
      "Meet Bubble-Futures-Bot (BFB), an innovative derivatives contract designed to streamline short-term speculation on any crypto asset, utilizing an on-chain oracle. Bubble-Futures-Bot (BFB) comprises futures EVM-compatible smart contracts featuring a 36-hour expiration cycle. This innovative tool empowers traders to effortlessly take long and short positions on top-tier blue-chip assets or the latest trending meme coins, whether on Ethereum or any other EVM-compatible chain. The primary objective of BFB is to reinvigorate market maker (MM) participation, addressing their hesitancy to provide liquidity for certain crypto assets arising from low liquidity concerns.": "Meet Bubble-Futures-Bot (BFB), an innovative derivatives contract designed to streamline short-term speculation on any crypto asset, utilizing an on-chain oracle. Bubble-Futures-Bot (BFB) comprises futures EVM-compatible smart contracts featuring a 36-hour expiration cycle. This innovative tool empowers traders to effortlessly take long and short positions on top-tier blue-chip assets or the latest trending meme coins, whether on Ethereum or any other EVM-compatible chain. The primary objective of BFB is to reinvigorate market maker (MM) participation, addressing their hesitancy to provide liquidity for certain crypto assets arising from low liquidity concerns.",
      "You’ll borrow funds from the lending pool at an interest rate of 3.6% for a 36 hour cycle to increase leverage on your trades which increases the chances of profits. Of Course the risk of increasing more is involved.": "You’ll borrow funds from the lending pool at an interest rate of 3.6% for a 36 hour cycle to increase leverage on your trades which increases the chances of profits. Of Course the risk of increasing more is involved.",
      "You’ll provide liquidity to the lending pool earning you 3.6% interest on your active lending every day. The problem with other decentralized lending pools is as the pool grows the chances of your funds being used there is lower. This is why our protocol prioritizes using funds from early lenders so they get the most out of the platform like FCFS.": "You’ll provide liquidity to the lending pool earning you 3.6% interest on your active lending every day. The problem with other decentralized lending pools is as the pool grows the chances of your funds being used there is lower. This is why our protocol prioritizes using funds from early lenders so they get the most out of the platform like FCFS.",
      "Futures trading is all about betting against the pricing, this is why Bubble Futures Bot will use Chain Link price oracle on Ethereum Chain to fetch real-time prices from centralized and decentralized trading platforms.": "Futures trading is all about betting against the pricing, this is why Bubble Futures Bot will use Chain Link price oracle on Ethereum Chain to fetch real-time prices from centralized and decentralized trading platforms.",
      "Kindly be aware that futures trading carries significant risks. The value of futures can vary, potentially leading to losses surpassing the initial investment. When trading such products, including those traded on margin, you might be required to make additional payments later. It is imperative not to invest funds dingdang.io you cannot afford to lose, as losses and liquidation are ever-present risks. Always engage in responsible trading practices.": "Kindly be aware that futures trading carries significant risks. The value of futures can vary, potentially leading to losses surpassing the initial investment. When trading such products, including those traded on margin, you might be required to make additional payments later. It is imperative not to invest funds dingdang.io you cannot afford to lose, as losses and liquidation are ever-present risks. Always engage in responsible trading practices.",
      "While many other meme projects operate on an inflationary model, Bubble Futures Bot stands out with its primary objective: buying back $Bubble tokens using 3% of the profit share generated from leveraged trading. According to our projections, the $Bubble token is anticipated to experience a deflation of approximately 30x within its fourth quarter of 2023.": "While many other meme projects operate on an inflationary model, Bubble Futures Bot stands out with its primary objective: buying back $Bubble tokens using 3% of the profit share generated from leveraged trading. According to our projections, the $Bubble token is anticipated to experience a deflation of approximately 30x within its fourth quarter of 2023.",
      "Transforming our Discord server and Telegram into a CHEX in under a minute. Now, our community members can trade and engage in trade discussions, all conveniently in one location.": "Transforming our Discord server and Telegram into a CHEX in under a minute. Now, our community members can trade and engage in trade discussions, all conveniently in one location.",
      "Compete tasks and get 1000 $bubble when you sign up!":"Compete tasks and get 1000 $bubble when you sign up!"
    ,"Invite code (Optional)":"Invite code (Optional)"
    ,"Enter invite code →":"Enter invite code →"
    ,"Connect wallet to claim":"Connect wallet to claim"
    ,"Connect":"Connect"
    ,"Join Dingdang.io":"Join Dingdang.io"
    ,"Telegram":"Telegram"
    ,"and get a verified role":"and get a verified role"
    ,"Join":"Join"
    ,"Discord":"Discord"
    ,"Complete tasks and claim":"Complete tasks and claim"
    ,"bubble":"bubble"
    ,"Claim":"Claim"
    ,"THE TOKEN POWERING THE MEMECONOMY":"THE TOKEN POWERING THE MEMECONOMY"
    ,"The governance and reward mechanism for the DingDang Ecosystem":"The governance and reward mechanism for the DingDang Ecosystem"
    ,"JOIN THE $BUBBLE TOKEN AIRDROP":"JOIN THE $BUBBLE TOKEN AIRDROP"
    ,"How to earn BUBBLE":"How to earn BUBBLE"
    ,"$BUBBLE token in the upcoming airdrop!":"$BUBBLE token in the upcoming airdrop!"
    ,"Sign up to the campaign":"Sign up to the campaign"
    ,"Earn bubble for participating in community tasks!":"Earn bubble for participating in community tasks!"
    ,"Refer your friends":"Refer your friends"
    ,"You will both receive 1500 bubble once they complete sign up tasks and reach 2000 Zealy points!":"You will both receive 1500 bubble once they complete sign up tasks and reach 2000 Zealy points!"
    ,"Select communities will be able to claim a bubble bonus!":"Select communities will be able to claim a bubble bonus!"
    ,"Bubble PASS":"Bubble PASS"
    ,"Holders will receive a generous token airdrop, and you can earn Bubble on top to maximise your claim!":"Holders will receive a generous token airdrop, and you can earn Bubble on top to maximise your claim!"
    ,"What are IDO Bubble POTIONS?":"What are IDO Bubble POTIONS?"
    ,"Bubble Potions multiply your $Bubble token during the upcoming Token Presale.":"Bubble Potions multiply your $Bubble token during the upcoming Token Presale."
    ,"Potions can be earned by climbing the bubble leaderboard, the more you earn, the better the multiplier!":"Potions can be earned by climbing the bubble leaderboard, the more you earn, the better the multiplier!"
    ,"Airdrop":"Airdrop"
    ,"$Bubble token airdrop campaign Live ($25000)":"$Bubble token airdrop campaign Live ($25000)"
   ,"30th Sep 2023": "30th Sep 2023",
      // "": "",
      // "": "",
      // "": "",
    },
  },
  Chinese: {
    translation: {
      "Home": "家",
      "Feature": "特征",
      "Tokenomics": "代币经济学",
      "FAQ": "常问问题",
      "Stay up to date": "保持最新状态",
      "Don't miss the latest Bubble bot news, guides, and insights": "不要错过最新的 Bubble 机器人新闻、指南和见解",
      "©2023 Bubble bot Copyright All Right Reserved": "©2023 泡泡机器人 版权所有",
      "Bubble Bot": "泡泡机器人",
      "Launch Bot": "启动机器人",
      "Whitepaper": "白皮书",
      "Staking": "质押",
      "Join the $bubble token airdrop": "加入 $bubble 代币空投",
      "Scan With QR Code": "使用二维码扫描",
      "Bubble Futures Trading Bot available on": "泡沫期货交易机器人可在",
      "Discord and Telegram": "不和谐和电报",
      "BFB": "疯牛病",
      "Bubble Futures Trading Bot": "泡沫期货交易机器人",
      "Suppose you had to open a long or short trade on your favorite memecoins at their launch. Now, thanks to the Bubble Futures Trading Bot available on Discord and Telegram, you have the capability to go long or short on any on-chain assets just 24 hours after their initial launch.": "假设您必须在您最喜欢的模因币推出时对其进行多头或空头交易。 现在，借助 Discord 和 Telegram 上提供的泡沫期货交易机器人，您可以在任何链上资产首次启动后 24 小时内做多或做空。",
      "Powered by": "供电",
      "Dingdang Labs": "ding当labs",
      "FEATURES": "特征",
      "First Futures Trading Bot, transforming Discord server and Telegram into a CHEX in under a minute. derivatives contracts featuring a 36-hour expiration cycle": "第一个期货交易机器人，在一分钟内将 Discord 服务器和 Telegram 转变为 CHEX。 具有 36 小时到期周期的衍生品合约",
      "Contemporary Challenges": "当代挑战",
      "The challenge associated with on-chain trading primarily stems from low liquidity levels": "与链上交易相关的挑战主要源于流动性水平低",
      "BFB Core Features": "BFB核心特性",
      "Straightforward Structure for Margin-Based Short-Term Speculation": "基于保证金的短期投机的简单结构",
      "BFB comprises futures": "BFB包含期货",
      "Much like traditional futures or perpetual contracts, these contracts can be": "就像传统的期货或永续合约一样，这些合约可以",
      "What Exactly (BFB)": "到底是什么（BFB）",
      "Meet Bubble-Futures-Bot (BFB), an innovative derivatives contract designed to": "泡沫期货机器人 (BFB) 是一种创新的衍生品合约，旨在",
      "As a trader:": "作为交易者：",
      "You’ll borrow funds from the lending pool at an interest rate of 3.6% for a": "您将以 3.6% 的利率从贷款池借入资金",
      "As a lender:": "作为贷款人：",
      "You’ll provide liquidity to the lending pool earning you 3.6% interest": "您将为贷款池提供流动性，从而赚取 3.6% 的利息",
      "Price Oracles:": "价格预言机：",
      "Futures trading is all about betting against the pricing, this is why Bubble Futures": "期货交易就是对定价做空，这就是为什么泡沫期货",
      "BFB is Deflationary": "BFB 是通缩的",
      "While many other meme projects operate on an inflationary model, Bubble Futures Bot stands": "尽管许多其他 meme 项目都采用通货膨胀模型运行，但 Bubble Futures Bot 却脱颖而出",
      "Contemporary Challenges in On-Chain Trading": "链上交易的当代挑战",
      "The challenge associated with on-chain trading primarily stems from low liquidity levels. Low liquidity can pave the way for price and funding manipulations, potentially causing forced liquidations. Consequently, market makers (MMs) often lack incentives to offer liquidity on-chain. Addressing this issue, we introduce the Bubble Futures Trading Bot as a viable solution.": "与链上交易相关的挑战主要源于流动性水平低。 低流动性可能为价格和资金操纵铺平道路，可能导致强制清算。 因此，做市商（MM）往往缺乏在链上提供流动性的动力。 为了解决这个问题，我们引入了泡沫期货交易机器人作为可行的解决方案",
      "- Straightforward Structure for Margin-Based Short-Term Speculation.": "- 基于保证金的短期投机的简单结构。",
      "- Daily Closure Ensured with Guaranteed $USDC Settlement": "- 保证每日关闭并保证 $USDC 结算",
      "- Final Settlement Aligned with the Underlying Asset Price Oracle atExpiration.": "- 最终结算与到期时的基础资产价格预言机一致。",
      "- Absence of day trading funding payments.": "- 缺乏日间交易资金支付。",
      "- Open for Creation by Any User, for Any Asset, Assuming Availability of an On-Chain Oracle for Underlying Asset Price Reference": "- 假设有链上预言机作为基础资产价格参考，任何用户都可以为任何资产创建开放",
      "- No Borrowing Required for Shorting the Underlying Asset": "- 卖空标的资产无需借款",
      "BFB comprises futures smart contracts characterized by a 36-hour closure cycle": "BFB 由期货智能合约组成，其特点是 36 小时关闭周期",
      "Much like traditional futures or perpetual contracts, these contracts can be traded in both long and short positions throughout their duration. Any positions that remain open at expiration, without being closed or rolled over, will be settled directly back into the trader's account as $USDC. This straightforward structure empowers traders to readily engage in speculative activities—whether it's going long or short—on their preferred crypto assets, including BFB on top-tier assets such as $BTC and $ETH, as well as the latest trending memecoins!": "与传统期货或永续合约非常相似，这些合约在整个存续期内都可以进行多头和空头交易。 任何在到期时保持未平仓状态且未平仓或展期的头寸都将作为 $USDC 直接结算回交易者的账户中。 这种简单的结构使交易者能够轻松地对其首选的加密资产进行投机活动（无论是做多还是做空），包括对 $BTC 和 $ETH 等顶级资产以及最新趋势的 memecoin 进行 BFB！",
      "While perpetual futures have gained popularity as a means of crypto speculation, their complexity has often hindered retail participation. Bubble dingdang.io Futures Bot offers a more streamlined trading solution, making it accessible to a wider audience.": "尽管永续期货作为一种加密货币投机手段而广受欢迎，但其复杂性往往阻碍了散户的参与。 Bubble dingdang.io 期货机器人提供了更简化的交易解决方案，使其可供更广泛的受众使用。",
      "Using Bubble Futures Bot as the first financial product offering (FPO) we encourage the community to provide liquidity to the lending pool just like users hold money in their banks ( while banks screw them up by making a lot of money on their money and rewarding them with almost nothing).": "使用 Bubble Futures Bot 作为第一个金融产品产品（FPO），我们鼓励社区向借贷池提供流动性，就像用户将钱存在银行一样（而银行则通过用他们的钱赚很多钱并奖励他们来搞砸他们） 几乎什么都没有）。",
      "What Exactly Is the Bubble Futures Bot": "泡沫期货机器人到底是什么",
      "Meet Bubble-Futures-Bot (BFB), an innovative derivatives contract designed to streamline short-term speculation on any crypto asset, utilizing an on-chain oracle. Bubble-Futures-Bot (BFB) comprises futures EVM-compatible smart contracts featuring a 36-hour expiration cycle. This innovative tool empowers traders to effortlessly take long and short positions on top-tier blue-chip assets or the latest trending meme coins, whether on Ethereum or any other EVM-compatible chain. The primary objective of BFB is to reinvigorate market maker (MM) participation, addressing their hesitancy to provide liquidity for certain crypto assets arising from low liquidity concerns.": "Bubble-Futures-Bot (BFB) 是一种创新的衍生品合约，旨在利用链上预言机简化对任何加密资产的短期投机。 Bubble-Futures-Bot (BFB) 包含与期货 EVM 兼容的智能合约，具有 36 小时到期周期。 这一创新工具使交易者能够轻松地在顶级蓝筹资产或最新流行的模因币上建立多头和空头头寸，无论是在以太坊还是任何其他 EVM 兼容链上。 BFB 的主要目标是重振做市商 (MM) 的参与，解决他们因流动性低而对某些加密资产提供流动性犹豫不决的问题。",
      "You’ll borrow funds from the lending pool at an interest rate of 3.6% for a 36 hour cycle to increase leverage on your trades which increases the chances of profits. Of Course the risk of increasing more is involved.": "您将以 3.6% 的利率从贷款池借入资金，周期为 36 小时，以增加交易杠杆，从而增加盈利机会。 当然，增加更多的风险是存在的。",
      "You’ll provide liquidity to the lending pool earning you 3.6% interest on your active lending every day. The problem with other decentralized lending pools is as the pool grows the chances of your funds being used there is lower. This is why our protocol prioritizes using funds from early lenders so they get the most out of the platform like FCFS.": "您将为借贷池提供流动性，每天通过活跃借贷赚取 3.6% 的利息。 其他去中心化贷款池的问题是，随着池子的扩大，您的资金被使用的机会就会降低。 这就是为什么我们的协议优先使用早期贷方的资金，以便他们充分利用 FCFS 等平台。",
      "Futures trading is all about betting against the pricing, this is why Bubble Futures Bot will use Chain Link price oracle on Ethereum Chain to fetch real-time prices from centralized and decentralized trading platforms.": "期货交易就是对定价进行对赌，这就是为什么 Bubble Futures Bot 将使用以太坊链上的 Chain Link 价格预言机从中心化和去中心化交易平台获取实时价格。",
      "Kindly be aware that futures trading carries significant risks. The value of futures can vary, potentially leading to losses surpassing the initial investment. When trading such products, including those traded on margin, you might be required to make additional payments later. It is imperative not to invest funds dingdang.io you cannot afford to lose, as losses and liquidation are ever-present risks. Always engage in responsible trading practices.": "请注意，期货交易存在重大风险。 期货的价值可能会有所不同，可能导致损失超过初始投资。 交易此类产品（包括保证金交易的产品）时，您可能需要稍后支付额外费用。 切勿将您无法承受损失的资金投入到 dingdang.io，因为损失和清算是始终存在的风险。 始终采取负责任的交易行为。",
      "While many other meme projects operate on an inflationary model, Bubble Futures Bot stands out with its primary objective: buying back $Bubble tokens using 3% of the profit share generated from leveraged trading. According to our projections, the $Bubble token is anticipated to experience a deflation of approximately 30x within its fourth quarter of 2023.": "虽然许多其他 meme 项目都采用通货膨胀模型运作，但 Bubble Futures Bot 因其主要目标而脱颖而出：使用杠杆交易产生的利润份额的 3% 回购 $Bubble 代币。 根据我们的预测，$Bubble 代币预计将在 2023 年第四季度经历约 30 倍的通货紧缩。",
      "Transforming our Discord server and Telegram into a CHEX in under a minute. Now, our community members can trade and engage in trade discussions, all conveniently in one location.": "在不到一分钟的时间内将我们的 Discord 服务器和 Telegram 转变为 CHEX。 现在，我们的社区成员可以在一个地点方便地进行交易和参与贸易讨论。",
      "Compete tasks and get 1000 $bubble when you sign up!":"报名参加任务即可获得 1000 美元泡泡！"
      ,"Invite code (Optional)":"邀请码（可选）"
      ,"Enter invite code →":"输入邀请码 →"
      ,"Connect wallet to claim":"连接钱包领取"
      ,"Connect":"连接"
      ,"Join Dingdang.io":"加入叮当io"
      ,"Telegram":"电报"
      ,"and get a verified role":"并获得经过验证的角色"
      ,"Join":"加入"
      ,"Discord":"不和谐"
      ,"Complete tasks and claim":"完成任务并领取"
      ,"bubble":"气泡"
      ,"Claim":"宣称"
      ,"THE TOKEN POWERING THE MEMECONOMY":"为记忆经济提供动力的代币"
      ,"The governance and reward mechanism for the DingDang Ecosystem":"叮当生态系统的治理和奖励机制"
      ,"JOIN THE $BUBBLE TOKEN AIRDROP":"加入 $BUBLE 代币空投"
      ,"How to earn BUBBLE":"如何赚取泡沫"
      ,"$BUBBLE token in the upcoming airdrop!":"即将空投的 $BUBBLE 代币！"
      ,"Sign up to the campaign":"报名参加该活动"
      ,"Earn bubble for participating in community tasks!":"参与社区任务即可赚取泡泡"
      ,"Refer your friends":"推荐你的朋友"
      ,"You will both receive 1500 bubble once they complete sign up tasks and reach 2000 Zealy points!":"一旦完成注册任务并达到 2000 Zealy 积分，你们都将获得 1500 个泡泡！"
      ,"Select communities will be able to claim a bubble bonus!":"选定的社区将能够领取泡沫奖金！"
      ,"Bubble PASS":"泡泡通行证"
      ,"Holders will receive a generous token airdrop, and you can earn Bubble on top to maximise your claim!":"持有者将收到慷慨的代币空投，您还可以赚取泡沫以最大化您的索赔！"
      ,"What are IDO Bubble POTIONS?":"什么是 IDO 泡泡药水？"
      ,"Bubble Potions multiply your $Bubble token during the upcoming Token Presale.":"Bubble Potions 在即将到来的代币预售期间使您的 $Bubble 代币倍增。"
      ,"Potions can be earned by climbing the bubble leaderboard, the more you earn, the better the multiplier!":"爬上泡泡排行榜即可获得药水，赚得越多，倍数就越高！"
      ,"Airdrop":"空投"
      ,"$Bubble token airdrop campaign Live ($25000)":"$Bubble 代币空投活动直播（$25000）"
      ,"30th Sep 2023":"2023 年 9 月 30 日"
      // ,"":""
      // ,"":""
      // ,"":""
      // ,"":""
      // ,"":""
      // ,"":""
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("locale") || "English",
  fallbackLng: ["English", "Chinese"],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
