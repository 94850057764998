/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LangSelect from "../utils/LangSelect";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { FaTelegram, FaMediumM } from "react-icons/fa";
import Timer from "../utils/Timer";

const Navbar = props => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [t, i18n] = useTranslation();
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const header = document.querySelector(".navbar");
  //     if (window.pageYOffset > 85) {
  //       header.classList.add("navbar-fixed-top");
  //     } else {
  //       header.classList.remove("navbar-fixed-top");
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      handleShow2(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light navbar-warpper '>
        <div className='container'>
          <a className='navbar-brand ' href='/'>
            <img
              src='/assets/logo1.png'
              className='nav-1-img'
              width='50'
              alt=''
            />
            {/* <img src='/assets/logo-cr.png' width='100' alt='' /> */}
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarText'
            aria-controls='navbarText'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarText'>
            <ul className='navbar-nav m-auto mb-2  '>
              <li className='nav-item h_nav-item active-link'>
                <a
                  rel='noreferrer'
                  href='https://pancakeswap.finance/swap?outputCurrency=0xE04Dd89D48119f845fDdC8d90bA11667AA87D809'
                  target='_blank'
                  title='Sale Launchpad'
                  className='nav-link h_nav-link'>
                  Buy $Bubble
                </a>
              </li>
              <li className='nav-item h_nav-item'>
                <a
                  rel='noreferrer'
                  href='https://bubble-bot.gitbook.io/bubblebot-guide'
                  target='_blank'
                  className='nav-link h_nav-link'>
                  Documents
                </a>
              </li>
              <li className='nav-item h_nav-item'>
                <a
                  rel='noreferrer'
                  href='https://stake.bubblebot.finance/'
                  target='_blank'
                  className='nav-link h_nav-link'>
                  {t("Staking")}
                </a>
              </li>
              <li className='nav-item h_nav-item'>
                <a
                  rel='noreferrer'
                  href='https://miner.bubblebot.finance/'
                  target='_blank'
                  className='nav-link h_nav-link'>
                  {t("BNB Miner")}
                </a>
              </li>
              {/* <li className='nav-item h_nav-item'>
                <Link
                  activeClass='active'
                  to='/airdrop'
                  className='nav-link h_nav-link'>
                  {t("Airdrop")}
                </Link>
              </li> */}

              <li className='nav-item h_nav-item'>
                <a
                  rel='noreferrer'
                  href='https://app.bubblebot.finance/'
                  target='_blank'
                  className='nav-link h_nav-link'>
                  {t("Dashboard")}
                </a>
              </li>

              {/* <li className='nav-item h_nav-item'>
                <Link
                  activeClass='active'
                  to='faqs'
                  className='nav-link h_nav-link'>
                  {t("FAQ")}
                </Link>
              </li> */}
              {/* <li className='nav-item h_nav-item'>
                  <LangSelect className='nav-link h_nav-link' />
                </li> */}
            </ul>
            <span className='navbar-text'>
              <ul className='navbar-nav m-auto mb-2 mb-lg-0 '>
                <li className='nav-item h_nav-item '>
                  <a
                    href='https://t.me/my_Bubble_bot'
                    target='_blank'
                    rel='noopener noreferrer'
                    title='Telegram'>
                    <button className='nav-link h_nav-link nav-btn px-4'>
                      {" "}
                      <strong className='m-1'>
                        <FaTelegram width={25} />
                      </strong>{" "}
                      Launch Bubble Bot
                    </button>
                  </a>
                </li>
              </ul>
            </span>
          </div>
        </div>{" "}
      </nav>
      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        className='mymodals'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Body>
          <img
            src='assets/tokenomics02.png'
            className='w-100 m-auto d-block rounded-3'
            alt=''
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        {...props}
        className='mymodals2'
        size='md'
        aria-labelledby='contained-modal-title-vcenter'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='text-center pb-4 '>
            <h2>$Bubble Live on Pancakeswap </h2>
            <br />
            {/* <h3 className='mb-3 ann'>Pcs Listing</h3> */}
            {/* <Timer /> */}
            {/* <br />
            <h5>
              <strong>Max:</strong>&nbsp;1.29 BNB
            </h5> */}
            {/* <br /> */}
            {/* <h5>
              <strong>Start In</strong>&nbsp;2024.03.17 14:30 (UTC)
            </h5> */}
            {/* <br /> */}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://pancakeswap.finance/swap?outputCurrency=0xE04Dd89D48119f845fDdC8d90bA11667AA87D809'>
              <button>Trade on PCS</button>
            </a>
            <br />
            <br />
            <div>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://stake.bubblebot.finance/'>
                <button>Staking</button>
              </a>
              &nbsp;&nbsp;
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://miner.bubblebot.finance/'>
                <button>BNB Miner</button>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
